.communication {
    padding: 2.5em;
    background: #e6eafa;
    min-height: 90vh;

    .channel-toast {
        position: fixed;
        bottom: 0px;
        right: 0px;
        padding: 3em;
    }

    input,
    select {
        border: 0px !important;
        padding: 0.5em 0.8em;
        border: 1px solid #e6eafa !important;
    }

    .editor-grid-area {
        display: grid;
        grid-template-areas:
        "INFO"
        "EDITOR";
        gap: 2em;
        margin-bottom: 2em;

        @media screen and (max-width: 992px) {
            grid-template-areas:
                "INFO"
                "EDITOR"
        }
    }

    #advice {
        grid-area: ADVICE;
    }

    #editor {
        grid-area: EDITOR;
        min-height: 500px;
        min-width: 30vw;

        #editor-content {
            min-height: 600px;
        }
    }

    #info {
        grid-area: INFO;
    }

    .post-list {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;

        padding: 1em .5em;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);

        .post-action-buttons {
            display: grid;
            grid-template-columns: auto auto 1fr;
            align-items: center;
            gap: 0.5em;
        }

        .rounded {
            height: 30px;
            border-radius: 50% !important;
            padding: 0 !important;
        }

        .ellipsis {
            width: 200px;
            p {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }

        &.post-outdated {
            filter: grayscale(1);
            background-color: #f5f5f5;
            border-radius: 10px;
        }
    }

    .content {
        row-gap: 2em;

        label {
            margin: 0;
        }

        .card {
            border: none;
            padding: 2em;
            border-radius: 10px;

            .tag-search {
                padding: 0;
            }

            .tags-grid {
                gap: 0.3em;
            }

            .suggestion-result {
                padding: 0.5em;
                border-radius: 5px;
                width: 100%;
                cursor: pointer;

                &:hover {
                    background-color: rgba($color: #000000, $alpha: 0.1);
                }
            }

            .event-informations {
                .switch {
                    position: relative;
                    display: inline-block;
                    width: 40px;
                    height: 22px;

                    input {
                        &:checked + .slider {
                            background-color: #16428a;
                        }

                        &:focus + .slider {
                            box-shadow: 0 0 1px #16428a;
                        }

                        &:checked + .slider:before {
                            -webkit-transform: translateX(15px);
                            -ms-transform: translateX(15px);
                            transform: translateX(15px);
                        }
                    }

                    /* The slider */
                    .slider {
                        position: absolute;
                        cursor: pointer;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: #ccc;
                        -webkit-transition: 0.4s;
                        transition: 0.4s;

                        &::before {
                            position: absolute;
                            content: "";
                            height: 15px;
                            width: 15px;
                            left: 4px;
                            bottom: 4px;
                            background-color: white;
                            -webkit-transition: 0.4s;
                            transition: 0.4s;
                        }

                        &.round {
                            border-radius: 34px;

                            &::before {
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }

            .image-upload {
                label {
                    cursor: pointer;
                    &:first-of-type {
                        cursor: default;
                    }
                    button {
                        pointer-events: none;
                    }
                }
            }

            .dates-input {
                .col-6 {
                    padding: 0;
                }
            }
        }
    }
}
