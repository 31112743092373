.post-tag {
    background-color: grey;
    border-radius: 20px;
    width: fit-content;
    padding: .5em;
    margin-left: .5em;

    &.tag-PRO {
        background-color: #16428a;
    }

    &.tag-PUBLIC {
        background-color: #51868a;
    }

    &.tag-LIVE {
        background-color: red;

        h2 {
            color: white !important;
        }
        
    }

    h2 {
        margin-bottom: 0;
        font-size: 12px;
        text-transform: uppercase;
        color: white;
        font-weight: bold;
    }
}