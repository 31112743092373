@keyframes ldio-kx3qkynwrdi {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.ldio-kx3qkynwrdi div {
    position: absolute;
    width: 120px;
    height: 120px;
    border: 20px solid #16428a;
    border-top-color: transparent;
    border-radius: 50%;
}

.ldio-kx3qkynwrdi div {
    animation: ldio-kx3qkynwrdi 1s linear infinite;
    top: 100px;
    left: 100px
}

.loadingio-spinner-rolling-enjvpmpkdor {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: transparent;
}

.ldio-kx3qkynwrdi {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
    /* see note above */
}

.ldio-kx3qkynwrdi div {
    box-sizing: content-box;
}

/* generated by https://loading.io/ */

.p-50{
    padding-top: 20%;
}