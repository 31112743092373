.list-group{
    height: 750px;
    overflow-y:auto;
    -webkit-overflow-scrolling: touch;
}

p{
    margin-top: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 5px;
}