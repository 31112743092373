#etablissement-list {
    table {
        td {
            text-transform: capitalize; 

            &.email {
                text-transform: lowercase;
            }
        }
    }
}

.anticon {
    vertical-align: baseline;
}

.rflex-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}