#verify-syndicat-modal {
    button {
        &.hide {
            filter: grayscale(1);
            transition: filter .2s ease-out;

            &:hover {
                filter: grayscale(0);
            }
        }
    }
}