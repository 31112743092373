.etude-view {
  width: 100%;
  height: 750px;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
}

.etude-view-buttons {
  width: 100%;
  height: 150px;
  padding-top: 60px;
  padding-bottom: 20px;
  padding-left: 10%;
  padding-right: 10%;
  align-self: end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.select {
  margin-right: 10%;
}

.etude-visibility {
  width: 100%;
  height: 300px;
  border-radius: 0.25rem;
}

.etude-name {
  width: 100%;
  height: 150px;
  padding-top: 47px;
  padding-left: 15%;
  padding-right: 15%;
  border-radius: 0.25rem;
}

.etude-view-grp-2 {
  display: flex;
  flex-direction: row;
}

.etude-view-grp-3 {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.etude-forms {
  width: 50%;
  height: 600px;
  border-radius: 0.25rem;
}

.etude-forms-header {
  height: 20%;
  padding-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
}

.forms-icon-header {
  margin-top: 10px;
}

.drag-drop-context {
  display: flex;
  flex-direction: row;
  height: 80%;
  padding-bottom: 1.5rem;
}

.forms {
  width: 40%;
  margin-left: 5%;
  border-radius: 0.25rem;
  overflow-y: auto !important;
}

.forms-to-add {
  width: 40%;
  margin-left: 10%;
  border-radius: 0.25rem;
  overflow-y: auto !important;
}

.etude-visibility-radios {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
}

.etude-visibility-radios-box {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
}
.etude-visibility-radios-texte {
  justify-self: center;
  text-align: center;
}

.etude-visibility-buttons {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 15%;
  padding-right: 15%;
}
