.site-layout {
    min-height: calc(100vh - 70px);
}

.site-layout-content {
    min-height: calc(100vh - 140px);
}

.animation-slide {
    -khtml-transition: margin-left 0.4s ease;
    -moz-transition: margin-left 0.4s ease;
    -ms-transition: margin-left 0.4s ease;
    -o-transition: margin-left 0.4s ease;
    -webkit-transition: margin-left 0.4s ease;
    transition: margin-left 0.4s ease;
}

.ant-layout-sider {
    .ant-layout-sider-children {
        overflow-y: auto;
    }
}