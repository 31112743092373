.tag-besoin {
    .anticon-close {
        vertical-align: 1px;
    }
}

.custom-color {
    .anticon {
        color: #bababa!important;
    }
}